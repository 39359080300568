<template>
  <div id="admin">
    <div class="left">
      <div class="nav-label">
        <div class="nav-title">导航</div>
        <div class="nav-item">
          <router-link to="/admin">
            <span>发表创作</span>
          </router-link>
          <router-link to="/admin/published">
            <span>管理创作</span>
          </router-link>
          <router-link to="/admin/comment">
            <span>文章评论</span>
          </router-link>
          <router-link to="/admin/leaving-msg">
            <span>管理留言</span>
          </router-link>
          <router-link to="/admin/links">
            <span>友情链接</span>
          </router-link>
          <router-link to="/admin/files">
            <span>管理文件</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
#admin {
  max-width: 1580px;
  margin: 0 auto;

  .left {
    width: 12%;
    padding: 10px 0 10px 28px;
    position: fixed;

    .nav-label {
      margin-bottom: 50px;

      .nav-title {
        font-size: 14px;
        line-height: 24px;
        color: #8d949e;
      }

      .nav-item {
        position: relative;

        .router-link-exact-active {
          font-weight: 600;
          color: #2254f4;

          span {
            padding-bottom: 3px;
            border-bottom: 3px solid #2254f4;
          }
        }

        a {
          display: block;
          font-size: 13px;
          line-height: 20px;
          color: #444950;
          margin: 20px 0;
        }
      }
    }
  }

  .right {
    width: 88%;
    margin-left: 12%;
    background: white;
    padding: 25px;
    min-height: 600px;
  }
}
</style>